import React, { useState } from "react";
import { Card, Table, Tooltip, Tabs, Pagination } from "antd";
import { useAuth } from "../context/auth-context";
import { useHistory } from 'react-router';
import { FileDoneOutlined, ShoppingCartOutlined, DatabaseOutlined, PlusCircleOutlined, BankOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { usePagination } from '../hooks';

const ListRetailers = (props) => {
    const history = useHistory();

    const { roleId } = props;

    const { data, gotoPage, currentPage, loading, total, fetchData } =
        usePagination(`/user/mapping?roleId=${roleId}&userRole=6`);

    const RetilersColumns = [
        {
            title: 'Fullname',
            dataIndex: 'RetailerFullName',
            key: 'RetailerFullName',
        },
        {
            title: 'RetailerMobile',
            dataIndex: 'RetailerMobile',
            key: 'RetailerMobile'
        },
        {
            title: 'Actions',
            key: 'action',
            width: '150px',
            render: (item) => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Tooltip placement="top" title="View Invoices" >
                        <FileDoneOutlined
                            style={{ color: '#4dbd74', fontSize: '15px' }}
                            onClick={() => history.push('/view-invoices', { userId: item.RetailerUserID })}
                        />
                    </Tooltip>
                    <Tooltip placement="top" title="View Orders" >
                        <ShoppingCartOutlined
                            style={{ color: '#4dbd74', fontSize: '15px' }}
                            onClick={() => history.push('/view-orders', { userId: item.RetailerUserID })}
                        />
                    </Tooltip>
                    <Tooltip placement="top" title="View payments ledger">
                        <DatabaseOutlined
                            style={{ color: '#4dbd74', fontSize: '15px' }}
                            onClick={() => history.push('/invoice-payments', { userId: item.RetailerUserID })}
                        />
                    </Tooltip>
                    <Tooltip placement="top" title="Create order">
                        <PlusCircleOutlined
                            style={{ color: '#4dbd74', fontSize: '15px' }}
                            onClick={() => history.push('/order', { userId: item.RetailerUserID, type: roleId })}
                        />
                    </Tooltip>
                    <Tooltip placement="top" title="View invoice ledger">
                        <BankOutlined
                            onClick={() => history.push('/invoice-ledger', { userId: item.RetailerUserID })}
                            style={{ color: '#4dbd74', fontSize: '15px' }}
                        />
                    </Tooltip>
                    <Tooltip placement="top" title="View points ledger">
                        <InfoCircleOutlined
                            onClick={() => history.push('/point-info', { userId: item.RetailerUserID })}
                            style={{ color: '#4dbd74', fontSize: '15px' }}
                        />
                    </Tooltip>
                </div>
            )
        }
    ]

    return (
        <>
            <Table
                loading={loading}
                size="small"
                bordered
                columns={RetilersColumns}
                pagination={false}
                dataSource={data}
            />
            <nav
                className="mt-4"
                style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
                <Pagination
                    showQuickJumper
                    current={currentPage}
                    defaultPageSize={10}
                    total={total}
                    onChange={page => gotoPage(page)}
                    showSizeChanger={false}
                />
            </nav>
        </>
    )
}


const ListDistributors = (props) => {

    const history = useHistory();

    const { roleId } = props;

    const { data, gotoPage, currentPage, loading, total, fetchData } =
        usePagination(`/user/mapping?roleId=4&userRole=5`);

    const distributorsColumns = [
        {
            title: 'Fullname',
            dataIndex: 'DistributorFullName',
            key: 'DistributorFullName',
        },
        {
            title: 'DistributorMobile',
            dataIndex: 'DistributorMobile',
            key: 'DistributorMobile'
        },
        {
            title: 'Actions',
            key: 'action',
            width: '150px',
            render: (item) => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Tooltip placement="top" title="View Invoices" >
                        <FileDoneOutlined
                            style={{ color: '#4dbd74', fontSize: '15px' }}
                            onClick={() => history.push('/view-invoices', { userId: item.DistributorUserID })}
                        />
                    </Tooltip>
                    <Tooltip placement="top" title="View Orders" >
                        <ShoppingCartOutlined
                            style={{ color: '#4dbd74', fontSize: '15px' }}
                            onClick={() => history.push('/view-orders', { userId: item.DistributorUserID })}
                        />
                    </Tooltip>
                    <Tooltip placement="top" title="View payments ledger">
                        <DatabaseOutlined
                            style={{ color: '#4dbd74', fontSize: '15px' }}
                            onClick={() => history.push('/invoice-payments', { userId: item.DistributorUserID })}
                        />
                    </Tooltip>
                    <Tooltip placement="top" title="Create order">
                        <PlusCircleOutlined
                            style={{ color: '#4dbd74', fontSize: '15px' }}
                            onClick={() => history.push('/order', { userId: item.DistributorUserID, type: roleId })}
                        />
                    </Tooltip>
                    <Tooltip placement="top" title="View invoice ledger">
                        <BankOutlined
                            onClick={() => history.push('/invoice-ledger', { userId: item.DistributorUserID })}
                            style={{ color: '#4dbd74', fontSize: '15px' }}
                        />
                    </Tooltip>
                    <Tooltip placement="top" title="View points ledger">
                        <InfoCircleOutlined
                            onClick={() => history.push('/points-info', { userId: item.DistributorUserID })}
                            style={{ color: '#4dbd74', fontSize: '15px' }}
                        />
                    </Tooltip>
                </div>
            )
        }
    ]

    return (
        <>
            <Table
                loading={loading}
                size="small"
                bordered
                columns={distributorsColumns}
                pagination={false}
                dataSource={data}
            />
            <nav
                className="mt-4"
                style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
                <Pagination
                    showQuickJumper
                    current={currentPage}
                    defaultPageSize={10}
                    total={total}
                    onChange={page => gotoPage(page)}
                    showSizeChanger={false}
                />
            </nav>
        </>
    )
}


const UserMapping = () => {
    const { user } = useAuth();

    const [status, setStatus] = useState(0);

    return (
        <Card key="user-mapping" title="User Mapping">

            <Tabs activeKey={status.toString()}
                onTabClick={(activeKey) => {
                    setStatus(parseInt(activeKey));
                }}>
                {user?.profile?.roleId === '4' || user?.roleId === '4' ?
                    <>
                        <Tabs.TabPane tab="Distributor" key="0">
                            <ListDistributors roleId={5} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Retailer" key="1">
                            <ListRetailers roleId={4} />
                        </Tabs.TabPane>
                    </>
                    : null
                }
                {user?.profile?.roleId === '5' || user?.roleId === '5' ?
                    <Tabs.TabPane tab="Retailer" key="2">
                        <ListRetailers roleId={5} />
                    </Tabs.TabPane>
                    : null
                }
            </Tabs>
        </Card>
    )
};

export default UserMapping;