import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import ProductDetail from "./ProductDetail";
import {
  AiOutlineCloseCircle,
  AiFillPlusCircle,
  AiFillMinusCircle,
} from "react-icons/ai";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router";

const CartOrder = () => {
  const [ProductDetails, setProductDetails] = useState([]);
  const [selectProduct, setSelectProduct] = useState();
  const history = useHistory();

  useEffect(() => {
    const cartData = JSON.parse(localStorage.getItem("cart"));

    if (cartData && cartData.products) {
      setProductDetails(cartData.products);
    }
  }, []);

  const createOrder = async () => {
    let totalAmount = 0;
    for (const item of ProductDetails) {
      const subTotal = item.SubTotal;
      totalAmount += subTotal;
    }

    const [error, result] = await asyncWrap(
      axios.post("/order", {
        orderJson: ProductDetails,
        discount: 0,
        amount: totalAmount,
      })
    );

    if (!result) {
      Swal.fire(error.response.data.message);
      return;
    } else {
      localStorage.removeItem("cart");
      Swal.fire(result.data?.message).then(() => {
        history.push("/product");
      });
    }
  };

  const removeProduct = productSkuID => {
    let existingCart = JSON.parse(localStorage.getItem("cart")) || {};
    if (existingCart.products) {
      existingCart.products = existingCart.products.filter(
        item => item.SkuID !== productSkuID
      );
      localStorage.setItem("cart", JSON.stringify(existingCart));
      setProductDetails(existingCart.products);
    }
  };

  const quantityChange = (index, value) => {
    let existingCart = JSON.parse(localStorage.getItem("cart")) || {};
    existingCart.products[index].Quantity = value;
    existingCart.products[index].SubTotal =
      existingCart.products[index].UnitPrice * value;
    localStorage.setItem("cart", JSON.stringify(existingCart));
    setProductDetails(existingCart.products);
  };

  const minusQuantityFunction = index => {
    let existingCart = JSON.parse(localStorage.getItem("cart")) || {};
    if (existingCart.products[index].Quantity > 1) {
      existingCart.products[index].Quantity =
        parseInt(existingCart.products[index].Quantity) - 1;
      existingCart.products[index].SubTotal =
        existingCart.products[index].UnitPrice *
        existingCart.products[index].Quantity;
      localStorage.setItem("cart", JSON.stringify(existingCart));
      setProductDetails(existingCart.products);
    }
  };

  const plusQuantityFunction = index => {
    let existingCart = JSON.parse(localStorage.getItem("cart")) || {};
    existingCart.products[index].Quantity =
      parseInt(existingCart.products[index].Quantity) + 1;

    existingCart.products[index].SubTotal =
      parseFloat(existingCart.products[index].UnitPrice) *
      parseInt(existingCart.products[index].Quantity);

    localStorage.setItem("cart", JSON.stringify(existingCart));
    setProductDetails(existingCart.products);
  };

  return (
    <Modal fullscreen={true} show={true}>
      <Modal.Body>
        <div className="d-flex flex-column">
          <div>order list</div>

          <div className="d-flex flex-column">
            <h3 className="text-start my-2">Best Sellers</h3>
            <>
              {ProductDetails &&
                ProductDetails.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setSelectProduct(item);
                      // setShowProductDetails(true);
                    }}
                    style={{ position: "relative" }}
                    className="d-flex flex-column my-2 shadow rounded-2 p-2"
                  >
                    <AiOutlineCloseCircle
                      style={{
                        width: "20px",
                        height: "20px",
                        position: "absolute",
                        right: "10px",
                      }}
                      onClick={() => removeProduct(item.SkuID)}
                    />
                    <div
                      // style={{ borderBottom: "1px solid #efefef" }}
                      className="d-flex flex-row justify-content-between py-2"
                    >
                      <div className="d-flex flex-row">
                        <div
                          style={{
                            borderRadius: "15px",
                            background: "#efefef",
                            width: "70px",
                            height: "70px",
                          }}
                        ></div>
                        <div className="d-flex flex-column text-start px-2">
                          <div>{item.SkuUniqueId}</div>
                          <div>{item.SubTotal}</div>
                          <div className="d-flex flex-row">
                            <AiFillMinusCircle
                              onClick={() => {
                                minusQuantityFunction(index);
                              }}
                              style={{
                                width: "20px",
                                height: "20px",
                                margin: "5px",
                              }}
                            />
                            <input
                              onChange={event => {
                                quantityChange(
                                  index,
                                  parseInt(event.target.value)
                                );
                              }}
                              min={1}
                              type="number"
                              value={item.Quantity}
                              defaultValue={10}
                              style={{
                                width: "30%",
                                border: "1px solid gray",
                                borderRadius: "10px",
                                textAlign: "center",
                              }}
                            />
                            <AiFillPlusCircle
                              onClick={() => {
                                plusQuantityFunction(index);
                              }}
                              style={{
                                width: "20px",
                                height: "20px",
                                margin: "5px",
                              }}
                            />
                          </div>
                          <div>{item.UnitPrice}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </>
            {ProductDetails.length <= 0 ? (
              <div>
                <h1 className="d-flex justify-content-center p-4">
                  Cart is Empty.
                </h1>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="my-2">
            <div className="fs-2">Manufacturing Address</div>
            <div
              className="d-flex flex-column shadow rounded-2 p-3"
              style={{ background: "#efefef" }}
            >
              <div style={{ width: "60%" }}>
                SR Manufactures Office Number 11, Block 333, Road 3 Bandra,
                Mumbai Maharashtra, India 585458
              </div>
            </div>
          </div>
          <div className="my-2">
            <div className="fs-2">Shipping Address</div>
            <div
              className="d-flex flex-column shadow rounded-2 p-3"
              style={{ background: "#efefef" }}
            >
              <div style={{ width: "60%" }}>
                SR Manufactures Office Number 11, Block 333, Road 3 Bandra,
                Mumbai Maharashtra, India 585458
              </div>
            </div>
          </div>

          <div>View Generated Invoice</div>

          <div
            onClick={() => {
              createOrder();
            }}
            style={{
              width: "100%",
              background: "#efefef",
              padding: "5px 10px",
              borderRadius: "5px",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            Place Order
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CartOrder;
