import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Table, Tabs, Pagination } from 'antd'
import { usePagination } from '../hooks';


const ListInvoices = (props) => {
    const { status, userId } = props;

    const { data, gotoPage, currentPage, loading, total, fetchData } =
        usePagination(`user-invoice?userId=${userId}&status=${status}`);

    const columns = [
        {
            title: 'Date',
            dataIndex: 'InvoiceDate',
            key: 'InvoiceDate'
        },
        {
            title: 'InvoiceID',
            dataIndex: 'InvoiceID',
            key: 'InvoiceID'
        },
        {
            title: 'Amount',
            dataIndex: 'TotalAmount',
            key: 'TotalAmount'
        }
    ]

    return (
        <>
            <Table
                size="small"
                bordered
                columns={columns}
                pagination={false}
                dataSource={data}
            />
            <nav
                className="mt-4"
                style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
                <Pagination
                    showQuickJumper
                    current={currentPage}
                    defaultPageSize={10}
                    total={total}
                    onChange={page => gotoPage(page)}
                    showSizeChanger={false}
                />
            </nav>
        </>
    )
}

const ViewInvoices = (props) => {

    const location = useLocation();
    const [status, setStatus] = useState(0);

    return (
        <Card title="View Invoices">
            <Tabs activeKey={status.toString()}
                onTabClick={(activeKey) => {
                    setStatus(parseInt(activeKey));
                }}>
                <Tabs.TabPane tab="Pending" key="0">
                    <ListInvoices status={status} userId={location.state.userId} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Approved" key="1">
                    <ListInvoices status={status} userId={location.state.userId} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Rejected" key="2">
                    <ListInvoices status={status} userId={location.state.userId} />
                </Tabs.TabPane>
            </Tabs>

        </Card>
    )
}

export default ViewInvoices;