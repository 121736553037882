import { Row, Col } from "antd";
import { Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import { useAuth } from "../context/auth-context";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { setCotivaToken, cortivaUrl } from "../constants/constants";
import { Spin } from 'antd';

const SpinList = (props) => {
  const { t } = useTranslation();

  const [spinData, setSpinData] = useState([]);
  const { user } = useAuth();
  const history = useHistory();

  const color = ["#FEA965", "#CC4056", "#A13964", "#4E866A"];
  const GetSpins = async () => {
    const [error, result] = await asyncWrap(axios.get("/spin"));
    if (!result) {
      console.log(error);
      return;
    }
    let data = result.data.data.map((item, i) =>
      i % 3 === 0
        ? { ...item, Color: color[i % 4] }
        : { ...item, Color: color[i % 4] }
    );
    // filtering according to the users state
    const filterStateWiseData = data.filter((item) => item.StateName === null || item.StateName.split(/,\s*/).includes(user?.StateName) || item.StateName.split(/,\s*/).includes(user?.profile?.StateName));
    console.log("filtered state =>>>>", filterStateWiseData)
    const filterDistrictWiseData = filterStateWiseData.filter((item) => item.DistrictName === null || item.DistrictName.split(/,\s*/).includes(user?.districtName) || item.DistrictName.split(/,\s*/).includes(user?.profile?.districtName))
    console.log("filtered district =>>>>>>", filterDistrictWiseData);
    setSpinData(filterDistrictWiseData);
  }

  const fetchCortivaToken = async () => {
    let data = {
      UserID: user?.profile?.userName || user?.userName,
      ClientName: "unomok",
      Key: "LsqLNMI5cPLguaRyHzyg3n3qNBVjQmtBhz3Sz"
    }

    const [error, result] = await asyncWrap(axios.post(`${cortivaUrl}/Authenticate`, data))
    if (!result) {
      console.log(error);
      return;
    }

    setCotivaToken(result.data.token)
  }

  useEffect(() => {
    GetSpins();
    fetchCortivaToken()
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        style={{ height: "95vh" }}
        className="d-flex flex-column justify-content-between"
      >
        <Row>
          {spinData && (
            spinData.map((item, index) => (
              <Col
                key={index}
                xs={22}
                style={{
                  marginTop: "40px",
                  marginLeft: "15px",
                }}
              >
                <Card
                  onClick={() => {
                    history.push(`/spin/${item.SpinAndWinID}`, { spin: { ...item } })
                  }}
                  style={{
                    backgroundColor: item.Color,
                    height: "160px",
                    boxShadow: "0px 4px 20px rgba(254, 169, 101, 0.5)",
                    borderRadius: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "90px",
                      height: "90px",
                      backgroundColor: "#FFCA9F",
                      borderRadius: "15px",
                      boxDhadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Card.Img
                      src={item.SpinImage}
                      style={{ width: "64px", height: "64px" }}
                    />
                  </div>
                  <Card.Text
                    style={{
                      marginTop: "5px",
                      textAlign: "center",
                      color: "#fff",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    {item.SpinAndWinName}
                  </Card.Text>
                </Card>
              </Col>
            ))
          )}
          {spinData.length === 0 && <div style={{
            display: "flex",
            justifyContent: "center",
            width: "100%"
          }}>
            <Spin />
          </div>}
        </Row>
      </div>

    </>
  );
};

export default SpinList;
