import React from "react";
import { BsChevronDown } from "react-icons/bs";
import { useHistory } from "react-router";
// import { CiMenuKebab } from "react-icons/ci";
import CustomCarousel from "../component/Home/CustomCarousel";
import { useLocation } from 'react-router-dom';


function Order() {

  const location = useLocation();

  const history = useHistory();

  return (
    <div className="d-flex flex-column p-3">
      <div className="d-flex flex-row justify-content-between my-2">
        <div className="d-flex flex-column text-start">
          <div>User Name</div>
          <div>Business card</div>
        </div>
        <div
          style={{
            background: "#efefef",
            width: "50px",
            height: "50px",
            borderRadius: "50%",
          }}
        ></div>
      </div>

      <div className="orderCard my-3 d-flex flex-column text-start p-2">
        <div className="d-flex flex-row justify-content-between">
          <div className="fs-5">Total Sales</div>
          <div className="d-flex flex-row">
            <div>Last Month</div>
            {/* <CiMenuKebab /> */}
          </div>
        </div>
        <div className="fs-1 py-2">$22,22,222</div>
        <div
          style={{ background: "#d9d9d9", width: "fit-content" }}
          className="py-1 px-3 rounded"
        >
          View in Detail
          <BsChevronDown style={{ marginLeft: "5px" }} />
        </div>
      </div>

      <div>
        <h2 className="text-start">DashBoard</h2>
        <div className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-between">
            <div className="order-card">Pending Orders</div>
            <div
              onClick={() => {
                history.push("/product", {userId: location.state.userId, type: location.state.type});
              }}
              className="order-card"
            >
              Place an Order
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="order-card">Update Invoices</div>
            <div
              onClick={() => {
                history.push("/target-achievement");
              }}
              className="order-card"
            >
              Targets & Achievements
            </div>
          </div>
        </div>
      </div>

      <div className="my-4">
        <CustomCarousel
          images={[
            "https://images.unsplash.com/photo-1536599018102-9f803c140fc1?auto=format&fit=crop&w=440&h=220&q=60",
            "https://images.unsplash.com/photo-1536599018102-9f803c140fc1?auto=format&fit=crop&w=440&h=220&q=60",
          ]}
        />
      </div>

      <div>
        <h2 className="text-start">Orders in process</h2>
        <div className="border rounded-4 d-flex flex-column orderCard">
          <div className="d-flex flex-row">
            <div className="p-2">
              <img
                style={{ background: "#d9d9d9" }}
                src="https://squaretoiletries.com/wp-content/uploads/2020/02/Spring-Air-Freshener-Pack-Orange-Fresh-product-info.png"
                width="80px"
                height="80px"
                className="p-1 rounded-4"
              />
            </div>
            <div className="p-2">
              <h6 className="text-start mb-0">A2 Paper</h6>
              <div className="order-process-card-text">150 GSM</div>
              <div className="order-process-card-text">Quality : 5 tons</div>
              <div className="order-process-card-text">
                price Quote : $180 ($36/ton)
              </div>
              <div className="order-process-card-text">
                Distributor : Rk Traders
              </div>
            </div>
            <div className="p-2">
              <div
                style={{ background: "#d9d9d9" }}
                className="px-3 py-1 rounded-2"
              >
                View
              </div>
              <div
                style={{ background: "#d9d9d9" }}
                className="px-3 py-1 rounded-2 mt-1"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <h2 className="text-start">Previous Orders</h2>
        <div className="orderCard p-2">
          <div
            style={{ borderBottom: "0.5px solid gray" }}
            className="d-flex flex-row justify-content-between p-2 align-items-center"
          >
            <div>RM Distributor</div>
            <div className="d-flex flex-column">
              <div>star</div>
              <div>since 2020</div>
            </div>
          </div>
          <div
            style={{ borderBottom: "0.5px solid gray" }}
            className="d-flex flex-row justify-content-between p-2 align-items-center"
          >
            <div>RM Distributor</div>
            <div className="d-flex flex-column">
              <div>star</div>
              <div>since 2020</div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <h2 className="text-start">Authorized Distributor</h2>
        <div className="orderCard p-2">
          <div
            style={{ borderBottom: "0.5px solid gray" }}
            className="d-flex flex-row justify-content-between p-2 align-items-center"
          >
            <div>RM Distributor</div>
            <div className="d-flex flex-column">
              <div>star</div>
              <div>since 2020</div>
            </div>
          </div>
          <div
            style={{ borderBottom: "0.5px solid gray" }}
            className="d-flex flex-row justify-content-between p-2 align-items-center"
          >
            <div>RM Distributor</div>
            <div className="d-flex flex-column">
              <div>star</div>
              <div>since 2020</div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <h2 className="text-start">Authorized Salesmen</h2>
        <div className="orderCard p-2">
          <div
            style={{ borderBottom: "0.5px solid gray" }}
            className="d-flex flex-row justify-content-between p-2 align-items-center"
          >
            <div>RM Distributor</div>
            <div className="d-flex flex-column">
              <div>star</div>
              <div>since 2020</div>
            </div>
          </div>
          <div
            style={{ borderBottom: "0.5px solid gray" }}
            className="d-flex flex-row justify-content-between p-2 align-items-center"
          >
            <div>RM Distributor</div>
            <div className="d-flex flex-column">
              <div>star</div>
              <div>since 2020</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Order;
