import { useEffect, useState } from "react";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import { Wheel } from "react-custom-roulette";
import { Button } from "reactstrap";
import CelebrationConfetti from "../component/Movement/CelebrationConfetti";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
// import ReactHowler from "react-howler";
import { useParams, useLocation } from "react-router";
import { getCotivaToken, cortivaUrl } from "../constants/constants";
import moment from "moment";
import { useAuth } from "../context/auth-context";
// import { Card, Table } from "antd";


const SpinAndWin = (props) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState();
  const [winning, setWinning] = useState();
  const [active, setActive] = useState(false);
  const params = useParams();
  const location = useLocation();
  const { user } = useAuth();
  const [spinStart, setSpinStart] = useState(false);
  // const [historyData, setHistoryData] = useState([]);
  const [totalCoupon, setTotalCoupon] = useState(0);

  const [soundPlaying, setSoundPlaying] = useState(false);

  const [totalSpins, setTotalSpins] = useState(0);
  const [playedSpins, setPlayedSpins] = useState(0);
  const dummyArr = new Array(18).fill(null)

  const playSound = () => {
    setPlaying(true);
  };

  const pauseSound = () => {
    setPlaying(false);
  };

  const GetItems = async () => {
    const [error, result] = await asyncWrap(
      axios.get(`spin/detail?spinAndWinId=${params.id}`)
    );

    if (!error) {

      let spinOptionsData = result.data.data.map((item) => {
        let result = { ...item, option: item.OptionName };
        if (item.SpinDetailImage) {
          result.image = {
            uri: item.SpinDetailImage,
            landscape: true,
            offsetX: 300,
            offsetY: 40,
            sizeMultiplier: 0.7
          }
        }
        return result
      })

      setOptions(spinOptionsData);
      setData(spinOptionsData);
    } else {
      console.log(error);
    }
  };

  const getSpinHistory = async () => {
    const [error, result] = await asyncWrap(
      axios.get(`spin/history?userName=${user?.userName || user?.profile?.userName}`)
    );

    if (!result) {
      console.log(error);
      return;
    }
    console.log(result.data.data)
    setHistoryData(result.data.data)
  }

  const getResult = async () => {
    // if there is no spin available
    if (totalSpins <= 0) {
      Swal.fire("You are not eligible to spin!")
      setSpinStart(false);
      return;
    }

    // playSound();
    setWinning(null);
    setActive(false);
    const [error, result] = await asyncWrap(
      axios.get(`spin/result?spinAndWinId=${params.id}`)
    );

    if (!result) {
      console.log(error);
      return;
    }
    setWinning(result.data.data[0]);
    if (result.data.data.length === 0) {
      Swal.fire("Snap, network issue, Please try again in 5 minutes.")
      setSpinStart(false);
      return
    }
    const selectedItem = options.findIndex((item) => {
      if (item.OptionName === result.data.data[0].OptionName) {
        return true;
      }
      return false;
    });
    setPrizeNumber(selectedItem);
    // can't spin if already completed all spins
    setPlayedSpins(result.data.data[0].TotalSpin)
    if (totalSpins <= result.data.data[0].TotalSpin) {
      Swal.fire("You have already played all the games! ")
      return;
    }
    setMustSpin(true);
  };

  const onStopSpinning = async () => {
    // pauseSound();
    setMustSpin(false);
    setSpinStart(false);
    setActive(true);
    Swal.fire(`You Won ${winning.OptionName}`);
    const data = {
      usersSpinID: 0,
      spinAndWinID: winning.SpinAndWinID,
      spinAndWinDetailID: winning.SpinAndWinDetailID,
      optionValue: winning.OptionValue,
    };

    const [error, result] = await asyncWrap(axios.post("/spin", data));
    if (!result) {
      console.log(error);
      return;
    }
    submitRewardToCortiva();
  };

  const submitRewardToCortiva = async () => {
    let data = {
      RetailerId: user?.profile?.userName || user?.userName,
      SchemeId: "10001",
      RewardName: winning.OptionName,
      ROOTSPoint: +winning.OptionValue,
    }

    let token = getCotivaToken();

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${cortivaUrl}/spinwheel/RetailerReward`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      data: data
    };

    const [error, result] = await asyncWrap(axios(config))
    if (!result) {
      console.log(error);
      return;
    }
  }

  const fetchCortivaData = async () => {
    const spinData = location.state.spin;

    let data = JSON.stringify({
      RetailerId: user?.profile?.userName || user?.userName,
      FromDate: moment(spinData.StartDate).format('YYYY-MM-DD'),
      ToDate: moment(spinData.EndDate).format('YYYY-MM-DD'),
      Hybrid: spinData.SkuUniqueId.split(',').map((item) => item.trim())
    });

    let token = getCotivaToken();

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${cortivaUrl}/spinwheel/RetailerCouponDetails`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      data: data
    };

    const [error, result] = await asyncWrap(axios(config))
    if (!result) {
      console.log(error);
      return;
    }

    let scanCount = 0
    // getting scan count
    result.data?.payload?.couponDetail?.map((item) => {
      scanCount += item.couponCount;
    })

    setTotalCoupon(scanCount);

    // Total spins which user can play
    let totalSpin = Math.floor(scanCount / spinData?.Scan)

    if (params.id === "13") {
      console.log("spin id>>", params.id, 20)
      setTotalSpins(1)
    } else {
      console.log("spin id>>", params.id, totalSpin)
      setTotalSpins(totalSpin)
    }
    console.log("scan count from cortiva", scanCount, "total spin according to our logic", totalSpin)

    // setTotalSpins(totalSpin)
  }

  useEffect(() => {
    GetItems();
    fetchCortivaData()
    // getSpinHistory()
    //eslint-disable-next-line
  }, [params.id]);


  const columns = [
    {
      title: 'Date',
      dataIndex: 'SpinDate',
      key: 'SpinDate'
    },
    {
      title: 'Spin win',
      dataIndex: 'OptionName',
      key: 'OptionName'
    },
    {
      title: 'Spin win Name',
      dataIndex: 'SpinAndWinName',
      key: 'SpinAndWinName'
    },
  ]


  return (
    <div>
      {/* <ReactHowler
        playing={playing}
        src="/assets/audio/spinandwin.mp3"
        loop={true}
      /> */}
      {data.length > 0 &&

        <div
          key="spin-wheel-something"
          style={{
            marginTop: "20px",
            background: "#fffef2",
            boxShadow: "#00000005",
            borderRadius: " 20px 20px 0px 0px",
            textAlign: "center",
          }}
        >
          <CelebrationConfetti active={active} />
          <div style={{
            display: "flex",
            justifyContent: "center"
          }}>

            <div className='wheel-border'>
              {dummyArr.map((_, i) => (<div key={i} className={`light-circle circle${i + 1} ${mustSpin && "border-animate"}`}></div>))}

              {/* circle image */}
              <img className='center-img' src="/assets/images/center.png" alt="centerImg" />
              <Wheel
                textColors={["#ffffff"]}
                backgroundColors={["#0171BB"]}
                data={data}
                mustStartSpinning={mustSpin}
                prizeNumber={prizeNumber}
                onStopSpinning={onStopSpinning}
                innerBorderWidth={15}
                innerBorderColor={"#D3C579"}
                radiusLineColor={"#D3C579"}
                outerBorderColor={"#D3C579"}
                outerBorderWidth={4}
                pointerProps={{ src: "/assets/images/spinArrow.svg", style: { rotate: "-100deg" } }}
              />
            </div>
          </div>
          <div style={{ marginTop: '10px' }}>
            <p>Total Coupons Scanned - {totalCoupon}</p>
            <p>Total Spins Eligible - {totalSpins} </p>
            <p>Total Spins Completed - {playedSpins}</p>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Button
              disabled={spinStart}
              style={{
                background: "#344A9F 0% 0% no-repeat padding-box",
                width: "218px",
                borderRadius: "20px",
              }}
              onClick={() => {
                setSpinStart(true)
                getResult();
              }}
            >
              {t("spin_the_wheel")}
            </Button>
          </div>
        </div>
      }
    </div>
  );
};

export default SpinAndWin;
