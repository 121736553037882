import React, { useState, useEffect } from "react";
import ProductDetail from "./ProductDetail";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import { Progress } from "antd";
import { GrFormUp, GrFormDown } from "react-icons/gr";
import moment from "moment";
import TargetAndAchievements from "./TargetAndAchievements";

export default function TargetList() {
  const [targetData, setTargetData] = useState([]);
  const [achievementsData, setAchievementsData] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);
  const [targetAchievementShow, setTargetAchievementShow] = useState(false);
  const [totalCompleteAchievements, setTotalCompleteAchievements] = useState(0);

  const toggleExpand = async (index, targetId) => {
    setExpandedItems(prevExpandedItems => {
      if (!prevExpandedItems[index]) {
        getTargetAchievementDetail(targetId);
      }
      const newExpandedItems = [...prevExpandedItems];
      for (let i = 0; i < newExpandedItems.length; i++) {
        if (i === index) {
          continue;
        } else {
          newExpandedItems[i] = false;
        }
      }
      newExpandedItems[index] = !newExpandedItems[index]; // Toggle the state
      return newExpandedItems;
    });
  };

  const getTargetAchievementDetail = async targetId => {
    const [error, result] = await asyncWrap(
      axios.get("target/details", { params: { id: targetId } })
    );
    if (!result) {
      console.log(error);
      return;
    }

    const [AchievementError, AchievementResult] = await asyncWrap(
      axios.get("target/achievements", { params: { id: targetId } })
    );
    if (!AchievementResult) {
      console.log(error);
      return;
    }

    const resultData = result.data?.data;
    const achievementData = AchievementResult.data?.data;
    let totalComplete = 0;

    const dataArray = resultData?.map(item => {
      const matchedAchievement = achievementData?.find(
        achievedItem => achievedItem.TargetSkuID === item.SkuID
      );

      if (
        item.Unit === "pieces" &&
        matchedAchievement?.AchivedQuantity >= item?.Value
      ) {
        totalComplete++;
      }
      if (
        item.Unit === "rupees" &&
        matchedAchievement?.AchivedSubTotal >= item?.Value
      ) {
        totalComplete++;
      }

      const data = {
        skuId: item.SkuID,
        skuUniqueId: item.SkuUniqueId,
        targetUnit: item.Unit,
        targetValue: item.Value,
        achievedQuantity: matchedAchievement
          ? matchedAchievement.AchivedQuantity
          : 0,
        achievedSubTotal: matchedAchievement
          ? matchedAchievement.AchivedSubTotal
          : 0,
      };

      return data;
    });
    console.log({ dataArray, totalCompleteAchievem: totalComplete });
    setAchievementsData(dataArray);
    setTotalCompleteAchievements(totalComplete);
  };

  useEffect(() => {
    getTargetData();
    getTargetAchievement();
    // eslint-disable-next-line
  }, []);

  const getTargetData = async () => {
    const [error, result] = await asyncWrap(axios.get("target"));
    if (!result) {
      console.log(error);
      return;
    }

    setTargetData(result.data?.data);
  };

  const getTargetAchievement = async () => {
    const [error, result] = await asyncWrap(axios.get("target/achievements"));
    if (!result) {
      console.log(error);
      return;
    }

    console.log(result.data?.data);
  };

  const getTargetDetails = async targetId => {
    const [error, result] = await asyncWrap(
      axios.get("target/details", { params: { id: targetId } })
    );
    if (!result) {
      console.log(error);
      return;
    }

    console.log(result.data?.data);
  };

  return (
    <>
      <TargetAndAchievements
        show={targetAchievementShow}
        setShow={setTargetAchievementShow}
        targetData={achievementsData}
        totalCompleteAchievements={totalCompleteAchievements}
      />
      <div>
        <div className="d-flex flex-column p-2">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="fs-1">Targets List</div>
            <div className="d-flex flex-row">
              <div
                style={{
                  background: "#efefef",
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                }}
              ></div>
            </div>
          </div>

          <div>
            {targetData &&
              targetData.map((item, index) => (
                <div className="d-flex flex-column rounded-4 shadow p-3 mt-4">
                  <div
                    className="d-flex flex-column"
                    onClick={() => toggleExpand(index, item.TargetID)}
                  >
                    <div className="d-flex flex-row justify-content-between">
                      <div style={{ width: "90%" }} className="fs-6 text-start">
                        {item.TargetName}
                      </div>
                      <GrFormDown
                        className={`target-icon ${
                          !expandedItems[index] ? "target-expanded" : ""
                        }`}
                      />
                    </div>
                    <div className="d-flex flex-column justify-content-start text-start mt-2">
                      <div>
                        Start Date :{" "}
                        {moment(item.StartDate).format("DD-MM-YYYY")}
                      </div>
                      <div>
                        End Date : {moment(item.EndDate).format("DD-MM-YYYY")}
                      </div>
                    </div>
                  </div>
                  {expandedItems[index] && (
                    <div className="p-2">
                      <div
                        key={index}
                        className="d-flex flex-column mt-4 text-start"
                      >
                        {achievementsData[0] && (
                          <>
                            <div className="fs-6">
                              {achievementsData[0]?.skuUniqueId}
                            </div>
                            <Progress
                              percent={
                                achievementsData[0]?.targetUnit === "rupees"
                                  ? (achievementsData[0]?.achievedSubTotal *
                                      100) /
                                    achievementsData[0]?.targetValue
                                  : achievementsData[0].targetUnit === "pieces"
                                  ? (achievementsData[0]?.achievedQuantity *
                                      100) /
                                    achievementsData[0]?.targetValue
                                  : 0
                              }
                              strokeWidth={15}
                            />
                          </>
                        )}
                        <div className="d-flex flex-row justify-content-between mt-4">
                          {achievementsData[1] && (
                            <div
                              style={{
                                width:
                                  achievementsData.length === 2
                                    ? "50%"
                                    : "auto",
                              }}
                            >
                              <div className="d-flex flex-column justify-content-center text-center">
                                <Progress
                                  type="circle"
                                  percent={
                                    achievementsData[1]?.targetUnit === "rupees"
                                      ? (achievementsData[1]?.achievedSubTotal *
                                          100) /
                                        achievementsData[1]?.targetValue
                                      : achievementsData[1].targetUnit ===
                                        "pieces"
                                      ? (achievementsData[1]?.achievedQuantity *
                                          100) /
                                        achievementsData[1]?.targetValue
                                      : 0
                                  }
                                  width={70}
                                />
                                <div>{achievementsData[1]?.skuUniqueId}</div>
                              </div>
                            </div>
                          )}

                          {achievementsData[2] && (
                            <div
                              style={{
                                width:
                                  achievementsData.length === 2
                                    ? "50%"
                                    : "auto",
                              }}
                            >
                              <div className="d-flex flex-column justify-content-center text-center">
                                <Progress
                                  type="circle"
                                  percent={
                                    achievementsData[2]?.targetUnit === "rupees"
                                      ? (achievementsData[2]?.achievedSubTotal *
                                          100) /
                                        achievementsData[2]?.targetValue
                                      : achievementsData[2].targetUnit ===
                                        "pieces"
                                      ? (achievementsData[2]?.achievedQuantity *
                                          100) /
                                        achievementsData[2]?.targetValue
                                      : 0
                                  }
                                  width={70}
                                />
                                <div>{achievementsData[2]?.skuUniqueId}</div>
                              </div>
                            </div>
                          )}

                          <div
                            style={{
                              width:
                                achievementsData.length === 2 ? "50%" : "auto",
                            }}
                            className="d-flex flex-column justify-content-center text-center"
                          >
                            <div className="fs-4">
                              {/* {moment(item.EndDate)
                              .startOf("hour")
                              .fromNow()} */}
                              {moment(item.EndDate).diff(
                                moment.now(),
                                "second"
                              ) < 0 ? (
                                <div
                                  style={{ color: "red", fontWeight: "600" }}
                                >
                                  Expire
                                </div>
                              ) : (
                                <div>
                                  {moment(item.EndDate)
                                    .startOf("hour")
                                    .fromNow()}
                                </div>
                              )}
                            </div>
                            {moment(item.EndDate).diff(moment.now(), "second") >
                            0 ? (
                              <div>Expire</div>
                            ) : null}
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setTargetAchievementShow(true);
                          }}
                          className="text-end text-decoration-underline mt-2"
                        >
                          more details
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>

          {/* <div className="d-flex flex-column mt-4">
          {targetData &&
            targetData.map((item, index) => (
              <div
                key={index}
                onClick={() => getTargetDetails(item.TargetID)}
                className="d-flex flex-column shadow rounded-4 p-3 mt-4 text-start"
              >
                <div className="fs-6">{item.TargetName}</div>
                <Progress className="mt-3" percent={50} strokeWidth={15} />
                <div className="d-flex flex-row justify-content-between mt-4">
                  <div className="d-flex flex-column justify-content-center text-center">
                    <Progress type="circle" percent={25} width={70} />
                    <div>Pending</div>
                  </div>
                  <div className="d-flex flex-column justify-content-center text-center">
                    <Progress type="circle" percent={25} width={70} />
                    <div>Completed</div>
                  </div>
                  <div className="d-flex flex-column justify-content-center text-center">
                    <div className="fs-4">
                      {moment(item.EndDate)
                        .startOf("hour")
                        .fromNow()}
                    </div>
                    <div>Pending</div>
                  </div>
                </div>
              </div>
            ))}
        </div> */}
        </div>
      </div>
    </>
  );
}
